html,body {
	font-size:16px;
	color:#333333;
	background-color:#EFF1F2;
	font-family:'Open Sans', sans-serif;
	font-weight:400;
}
body.ReactModal__Body--open{
	overflow:hidden;
}
:focus {
    outline: none;
}
a,
a:hover{
	color:#00aeef;
}
hr{
	border-top:4px solid rgba(0,0,0,.1);
}
button:focus,
a:focus {
    outline:0px none;
}

.whole-container{
	padding: 65px 15px 0px 74px;
}

#login{
	background-image:url('../Images/login_bg.jpg');
	background-repeat: no-repeat;	
	background-color:#EFF1F2;
	background-position: center top;
}
#login .logo{
	height:278px;
	display:flex;
	align-items:center;
	justify-content: center;
	flex-direction:column;
}
#login .logo .version{
	color:#603813;
	text-align:center;
	font-size:0.75rem;
}
#login .panel{
	background-color:#fff;
	width:484px;
	text-align:center;
	position:relative;
	margin:0 auto;
	padding:4px;
	box-shadow:0px 0px 5px #cbcbcb;
}
#login .inner-panel{
	border:1px solid #eeeeee;
	padding:35px;
}

#login .panel a{
    font-size:0.75rem;
}
#login .copy-tag{
	position: absolute;
    font-size: 0.75rem;
    bottom: -30px;
    left: 0px;
    color: #bbbbbb;
    width:100%;
    text-align:center;
}
#login .input-group-prepend img{
	width:30px;
}
#header{
	background-color:#ffffff;
	width:100%;
	position:fixed;
	top:0px;
	left:0px;
	z-index:1;
	box-shadow:0px 0px 5px #cccccc;
}
#header ul{
	list-style-type:none;
}
#header ul li{
	border-right:1px solid rgba(0,0,0,.125);;
}
#header ul li.logo{
	padding:0px;
	text-align:center;
}
#header ul li.logo img{
	height: 30px;
	margin-right: 10px;
}
#header ul li a,
#header ul li button{
	padding:10px;
	display:block;
	line-height: 25px;
	font-size: 0.75rem;
	color:#00aeef;
}
#header ul li button{
	border:0px none;
	cursor:pointer;
	background-color:#ffffff;
}
#header ul li a:hover,
#header ul li button:hover,
#header ul li a.active,
#header .sideBar ul li button:hover{
	color:#fff;
	background-color:#00aeef;
	text-decoration:none;
}
#header ul li.user{
	border-left:1px solid rgba(0,0,0,.125);
}
#header ul li.logo{
	width:230px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}
#header ul li img{
	max-width:100%;
	height: 23px;
}
#header .glyphicons-door{
	font-size: 1.06rem;
}
#header .sideBar{
	position:fixed;
	top:45px;
	left:-164px;
	height:100%;
	width:220px;
	background-color:#10121c;
	z-index:1;
	transition:0.5s all;
}
#header .sideBar span{
	display:none;
}
#header .sideBar ul li a,
#header .sideBar ul li button{
	text-align:right;
	padding-right:13px;
	transition:0.5s all;
}
#header .sideBar ul li button{
	width: 100%;
	background-color:#10121c;
}
#header .sideBar.open ul li a,
#header .sideBar.open ul li button{
	text-align:left;
	padding-right:0px;
}
#header .sideBar ul li button,
#header .sideBar ul li a{
	color:#dddddd;
	padding-left:0px;
}
#header .sideBar.open{
	left:0px;
}
#header .sideBar.open span{
	display:inline-block;
}
#header .sideBar ul{
	margin:0px;
	padding:0px;
	background-color: #10121c;
}
#header .sideBar ul li{
	border-right:0px none;
	border-left:0px none;
	border-bottom:1px solid #2d3144;
	position:relative;
}
#header .sideBar ul li ul{
	position: absolute;
    right: -220px;
    top: -1px;
    width: 220px;
}
#header .sideBar.open ul li ul{
	position: relative;
    right: auto;
    top: auto;
}

#header  .sideBar ul li ul.hidden{
	display:none;
}
#header  .sideBar.open ul li ul li{
	margin-left:26px;
}
#header  .sideBar ul li ul li.last{
	border-bottom:0px none;
}
#header .sideBar ul li ul li a{
	border-left:2px solid #10121c;
	padding: 5px; 
	font-size:0.7rem;
}
#header .sideBar ul li ul li a:hover{
	background-color:#002837;
	border-left:2px solid #002837;
}
#header .sideBar ul li ul li a.active{
	color:#fff;
	background-color:#002837;
	border-left:2px solid #002837;
}
#header li.menuMobile{
    display:none;
}
#layout{
	height:100%;
}
#notification{
    position:fixed;
    width:300px;
    bottom:20px;
	right:20px;	
	z-index:99999;
}
#notification .notify{
    margin-top:15px;
    padding:10px;
    background-color:#ffffff;
    border:1px solid rgba(0,0,0,.125);	
}
#notification .notify .alert{
    margin-bottom:0px;	
}

.data-list{
	list-style-type: none;
	padding:0px;
	margin:0px;
}
.data-list-item-content{
	display:flex;
	border-bottom:1px solid #ddd;
	justify-content: center;
    align-items: center;
}
.data-list-item.header{
	border-bottom:4px solid #ddd;
}
.data-list-item.header div{
	font-weight:bold;
	font-size:0.85rem;
}
.data-list-item-content div,
.data-list-item-content .item{
	padding:10px;
	flex:1;
	font-size:0.85rem;
	margin:0px;
}
.data-list-item-content div div{
	padding:0px;
}
.data-list-item-content.categories div{
	flex:1;
}
.data-list-item-content.links div{
	flex:none;
	width:20%;
}

.data-list-item-content.line-top{
	border-top:4px solid #ddd;
}

.data-list-item-content .static{
	flex:0 0 140px;
}
.data-list-item-content .static.small{
	flex:0 0 80px;
}
.data-list-item-content .static.smallest{
	flex:0 0 40px;
}
.data-list-item-content.deleted *{
	text-decoration: line-through;
}
.customer-list{
	list-style-type: none;
	padding:0;
	margin:0;
}
.customer-list li{
	padding:10px 10px;
	font-size:13px;
	border-bottom:1px solid #ddd;
	cursor:pointer;
}
.customer-list li:hover{
	background-color:#00aeef;
	color:#fff;
}
.article-list-img{
	height: 25px;
}

.link-selector{
	height: 150px;
	background-color: #eeeeee;
	overflow-y: scroll;
}

textarea.form-control,
input.form-control,
select.form-control,
select.form-control:focus,
select.form-control:active,
input.form-control:focus,
input.form-control:active,
.input-group-text,
.form-control:disabled, 
.form-control[readonly]{
	background-color:#eeeeee;
	border:0px none;
	color:#7d7d7d;
	box-shadow:none;
	font-size:0.75rem;
	padding:15px;
	height:46px;
}
textarea.form-control{
	height:100px;
}
.edit-icon,
.remove-icon{
	height:16px;
	cursor:pointer;
}
.content-icon,
.print-icon{
	height:24px;
	cursor:pointer;
}
.move-icon{
	height:18px;
}
label{
	font-size: 0.875rem;
}
label.input-label{
	font-weight:bold;
}
.input-group-text{
	font-size:1rem;
}
.table th{
	font-size:0.75rem;
	font-weight:700;
	padding: .5rem;
}
.table td{
	font-size:0.75rem;
	padding: .5rem;
}
.alert{
	font-size:0.875rem;
}
.btn{
	font-size:0.75rem;
	text-transform: uppercase;
	padding:12px;
	font-weight:700;
}
.btn-thiner{
	padding:5px 12px;
}
.btn-primary {
    background-color: #8dc63f;
    border-color: #8dc63f;
}
.btn-primary:hover {
    background-color: #8dc63f;
    border-color: #8dc63f;
}

.btn-blue {
    background-color: #00aeef;
    border-color: #00aeef;
}
.btn-blue:hover {
    background-color: #0097cf;
    border-color: #0097cf;
}
.btn-delete-files{
	line-height: 10px;
    padding: 10px;
	height: 35px;
	border: 1px solid #ced4da;
}
.btn-delete-files:hover{
	border: 1px solid #ced4da;
}
.nav-tabs{
	margin-bottom:20px;
}
.nav-link {
    padding: 1rem 1.5rem;
}
.nav-tabs .nav-link.disabled{
	color:#cccccc;
	cursor:not-allowed;
	pointer-events:initial;
}
.nav-tabs .nav-link.active{
	background-color:#00aeef;
	color:#fff;
}
.delete-checked{
	vertical-align: middle;
	margin-left: 4px;
}
.info-desc{
	font-size:12px;
	font-style: italic;
}
.image-mirror{
	-webkit-transform: scaleX(-1);
  	transform: scaleX(-1);
}
.gray-border{
	border:1px solid #ddd;
}
.whole-loading{
	display:flex;
	height:100%;
	align-items:center;
	justify-content:center;
	padding: 50px 0px;
}
.react-datepicker-popper{
	z-index: 10 !important;
	width:330px;
}
.loading{
	text-align:center;
	font-size:0.875rem;
}
.no-flex{
	flex:none !important;
}
.no-margin{
	margin:0px !important;
}
.w-40{
	width:40px;
}
.fs-14{
	font-size:0.875rem;
}
.pl-15{
	padding-left:15px !important;
}
.p-15{
	padding:15px !important;
}
.bold{
	font-weight:bold;
}
.hide{
	display:none;
}
.badge.not-specified{
	color:#fff;
}
.maxwidth-100{
	max-width:100%;
}

.modal-lg, .modal-xl {
    max-width: 1199px !important;
}
.modal-news {
    max-width: 620px !important;
}
.modal-max {
	max-width: 100% !important;
	padding:0 !important;
}
.custom-file-input{
	cursor:pointer;
}
.custom-file-label::after {
	content: "Vybrat" !important;
	background-color:#00aeef;
	color:#fff;
}

.cursor-pointer{
	cursor:pointer;
}

.ReactModal__Overlay {
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index:1300 !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content.modal-dialog {
  border: none;
  background-color: transparent;
}

.ReactCrop__image{
	max-height:100% !important;
}
.modal-max .modal-content {
	border-radius:0px;
	min-height:100vh;
}
.modal-max .modal-header {
    padding: 0.5rem;
}
.modal-max .modal-header h4{
	font-size:0.85rem;
	line-height:1.5rem;
}
.modal-max .modal-header .close {
    padding: 0;
    margin: 0 5px 0 0;
    line-height: 1.5rem;
    font-size: 1rem;
}
.modal-max .modal-body {
	padding:0px;
}
.modal-max .modal-body iframe{
	width:100%;
	height:calc(100vh - 49px);
	border:0px none;
}
.modal-max .form-check{
	display: flex !important;
    margin-left: auto;
    margin-right: 30px;
}
.form-check-input {
    margin-top: 0.4rem;
}
.react-datepicker-popper{
	z-index: 10 !important;
}
.modal-body .react-datepicker-wrapper{
	width:100%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}

.red{
	color: red !important;
}
.green{
	color: #8dc63f !important;
}

.spinner {
  margin: 10px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #00aeef;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right:2px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4);}  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

@media (min-width: 576px){
	.modal-dialog {
		max-width: 500px;
		margin: 0rem auto;
		padding: 1.75rem 0rem;
	}
}


.fs1{
	font-size: 1px;
}
.fs2{
	font-size: 2px;
}
.fs3{
	font-size: 3px;
}
.fs4{
	font-size: 4px;
}
.fs5{
	font-size: 5px;
}
.fs6{
	font-size: 6px;
}
.fs7{
	font-size: 7px;
}
.fs8{
	font-size: 8px;
}
.fs9{
	font-size: 9px;
}
.fs10{
	font-size: 10px;
}
.fs11{
	font-size: 11px;
}
.fs12{
	font-size: 12px;
}
.fs13{
	font-size: 13px;
}
.fs14{
	font-size: 14px;
}
.fs15{
	font-size: 15px;
}
.fs16{
	font-size: 16px;
}
.fs17{
	font-size: 17px;
}
.fs18{
	font-size: 18px;
}
.fs19{
	font-size: 19px;
}
.fs20{
	font-size: 20px;
}
.fs21{
	font-size: 21px;
}
.fs22{
	font-size: 22px;
}
.fs23{
	font-size: 23px;
}
.fs24{
	font-size: 24px;
}
.fs25{
	font-size: 25px;
}
.fs26{
	font-size: 26px;
}
.fs27{
	font-size: 27px;
}
.fs28{
	font-size: 28px;
}
.fs29{
	font-size: 29px;
}
.fs30{
	font-size: 30px;
}
.fs35{
	font-size: 35px;
}
.fs40{
	font-size: 40px;
}
.fs45{
	font-size: 45px;
}
.fs50{
	font-size: 50px;
}

